.formTitle {
  font-family: "SF Pro Display", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 54px;
  line-height: 110%;
  color: #000000;
  margin: 0 0 36px;
}

@media screen and (max-width: 860px) {
  .formTitle {
    font-size: 34px;
  }
}
