.container {
  max-width: 1200px;
  padding: 0 50px;
  margin: 0 auto;
  width: 100%;
}

@media screen and (max-width: 730px) {
  .container {
    padding: 0 20px;
  }
}
