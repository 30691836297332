.root {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.text {
  font-size: 34px;
  font-style: normal;
  font-weight: 600;
  line-height: 110%;
  letter-spacing: -0.68px;
  margin: 40px auto 50px;
}
