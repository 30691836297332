.container {
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 35px;
  min-height: 60px;
}

.label {
  padding-right: 30px;
  text-align: left;
  flex: 1;
  max-width: 200px;
  font-weight: 600;
}

.fileInput {
  display: none;
}

.image {
  width: 60px;
  height: 60px;
  margin-left: 30px;
  background-size: cover;
  background-position: 50% 50%;
  border-radius: 5px;
  border: 1px solid black;
  position: relative;
}

.imageOverlay {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  top: 0;
  left: 0;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition: opacity 0.3s;
}

.image:hover .imageOverlay {
  opacity: 1;
}

@media screen and (max-width: 820px) {
  .container {
    align-items: start;
    flex-direction: column;
  }

  .label {
    margin-bottom: 16px;
  }

  .image {
    width: 60px;
    height: 60px;
    margin-left: 0;
    margin-right: auto;
    margin-top: 18px;
  }
}

@media screen and (max-width: 730px) {
  .button {
    width: 100%;
  }
}
