@font-face {
  src: url('./SF-Pro-Display-Light.otf') format('otf'),
    url('./SF-Pro-Display-Light.woff2') format('woff2');
  font-family: 'SF Pro Display';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
}

@font-face {
  src: url('./SF-Pro-Display-LightItalic.otf') format('otf'),
    url('./SF-Pro-Display-LightItalic.woff2') format('woff2');
  font-family: 'SF Pro Display';
  font-style: italic;
  font-weight: 300;
  font-display: swap;
}

@font-face {
  src: url('./SF-Pro-Display-Regular.otf') format('otf'),
    url('./SF-Pro-Display-Regular.woff2') format('woff2');
  font-family: 'SF Pro Display';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
}

@font-face {
  src: url('./SF-Pro-Display-RegularItalic.otf') format('otf'),
    url('./SF-Pro-Display-RegularItalic.woff2') format('woff2');
  font-family: 'SF Pro Display';
  font-style: italic;
  font-weight: 400;
  font-display: swap;
}

@font-face {
  src: url('./SF-Pro-Display-Medium.otf') format('otf'),
    url('./SF-Pro-Display-Medium.woff2') format('woff2');
  font-family: 'SF Pro Display';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
}

@font-face {
  src: url('./SF-Pro-Display-MediumItalic.otf') format('otf'),
    url('./SF-Pro-Display-MediumItalic.woff2') format('woff2');
  font-family: 'SF Pro Display';
  font-style: italic;
  font-weight: 500;
  font-display: swap;
}

@font-face {
  src: url('./SF-Pro-Display-Semibold.otf') format('otf'),
    url('./SF-Pro-Display-Semibold.woff2') format('woff2');
  font-family: 'SF Pro Display';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
}

@font-face {
  src: url('./SF-Pro-Display-SemiboldItalic.otf') format('otf'),
    url('./SF-Pro-Display-SemiboldItalic.woff2') format('woff2');
  font-family: 'SF Pro Display';
  font-style: italic;
  font-weight: 600;
  font-display: swap;
}

@font-face {
  src: url('./SF-Pro-Display-Bold.otf') format('otf'),
    url('./SF-Pro-Display-Bold.woff2') format('woff2');
  font-family: 'SF Pro Display';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
}

@font-face {
  src: url('./SF-Pro-Display-BoldItalic.otf') format('otf'),
    url('./SF-Pro-Display-BoldItalic.woff2') format('woff2');
  font-family: 'SF Pro Display';
  font-style: italic;
  font-weight: 700;
  font-display: swap;
}

@font-face {
  src: url('./SF-Pro-Display-Black.otf') format('otf'),
    url('./SF-Pro-Display-Black.woff2') format('woff2');
  font-family: 'SF Pro Display';
  font-style: normal;
  font-weight: 900;
  font-display: swap;
}

@font-face {
  src: url('./SF-Pro-Display-BlackItalic.otf') format('otf'),
    url('./SF-Pro-Display-BlackItalic.woff2') format('woff2');
  font-family: 'SF Pro Display';
  font-style: italic;
  font-weight: 900;
  font-display: swap;
}
