.checkbox-container {
  display: flex;
  align-items: center;
  margin-right: 8px;
  font-size: 16px;
  padding: 8px 16px;
  font-weight: 500;
  border: 1px solid #A0A0A0;
  line-height: 120%;
  background: #FFF;
  color: #000;
  line-height: 1;
  border-radius: 30px;
  cursor: pointer;
  box-sizing: border-box;
}

.checkbox_active {
  border-color: #000;
  background: #000;
  color: #FFF;
}

.checkbox-container:last-child {
  margin-right: 0;
}
