.menu {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.menuLink {
  white-space: nowrap;
  color: #000;
  background: none;
  border: none;
  cursor: pointer;
  font-size: 18px;
  display: inline-block;
  padding: 0;
  font-weight: 500;
  line-height: 110%;
  margin-bottom: 24px;
}

.menuLink:last-child {
  margin-bottom: 0px;
}

@media screen and (max-width: 610px) {
  .menu {
    max-width: 308px;
    margin: 0 auto 20px;
    width: 100%;
  }

  .menuLink {
    width: 100%;
  }

  .menuButton {
    width: 100%;
  }
}