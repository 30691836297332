.loading {
  position: fixed;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100%;
  line-height: 100vh;
  text-align: center;
}

