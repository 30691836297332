.tag {
  display: flex;
  align-items: center;
  font-size: 16px;
  padding: 8px 16px;
  font-weight: 500;
  border: 1px solid #a0a0a0;
  line-height: 120%;
  background: #fff;
  color: #000;
  line-height: 1;
  text-transform: capitalize;
  border-radius: 30px;
}
