.popup {
  position: fixed;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 3;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.1);
}

.popup__content {
  max-width: 300px;
  width: 100%;
  position: relative;
  color: #000;
  padding: 40px;
  border-radius: 20px;
  background-color: #fff;
  box-shadow: 0px 15px 45px rgba(107, 106, 131, 0.15);
}

.popup__close {
  position: absolute;
  right: -32px;
  cursor: pointer;
  top: 0px;
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.popup__title {
  font-family: "Raleway", Arial, Helvetica, sans-serif;
  font-weight: 500;
  font-size: 18px;
  line-height: 130%;
  text-align: center;
  margin: 0;
}

.popup__buttons {
  display: flex;
  margin-top: 20px;
  justify-content: center;
}

.popup__button {
  max-width: 73px;
}

.popup__button:last-child {
  border: none;
  margin-left: 4px;
}
