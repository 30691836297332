.textarea {
  margin-bottom: 35px;
  font-family: 'SF Pro Display', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
}

.textareaLabel {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.textareaLabelText {
  margin-right: 30px;
  text-align: left;
  min-width: 170px;
  font-weight: 600;
}

.textareaField {
  background: #fff;
  border: 1px solid rgba(0, 0, 0, 0.5);
  box-sizing: border-box;
  border-radius: 6px;
  resize: none;
  outline: none;
  font-family: 'SF Pro Display', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #000;
  padding: 12px 18px;
  width: 100%;
}

.textareaField:focus {
  border: 1px solid #4a61dd;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.1);
}

@media screen and (max-width: 820px) {
  .textareaLabel {
    flex-direction: column;
    align-items: stretch;
  }

  .textareaLabelText {
    margin-bottom: 10px;
  }

  .textareaField {
    width: 100%;
  }
}
